@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;
    background: white;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 15s slide infinite linear;
  }
  
  .logos-slide img {
    height: 100px;
    margin: 0 40px;
  }

  @media screen and (max-width:768px) {
    .logos:before {
      left: 0;
      background: transparent;
    }
    
    .logos:after {
      right: 0;
      background: transparent;
    }
  }

  @media screen and (max-width:600px) {
    .logos {
      padding: 30px 0;
    }
  }