.gallery {
    display: grid;
    gap: 15px;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 300px;
    justify-content: center;

}

.box {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    background-color: #222;
    background-blend-mode: hard-light;
    transition: all .5s ease-in-out;
}

.box:hover {
    box-shadow: 0 0 20px black;
}

.row-2 {
    grid-row: span 2;
}

.col-2 {
    grid-column: span 2;
}

.box img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    opacity: 0.8;
    transition: .5s;
}


.box:hover img {
    transform: scale(1.04);
}


.box .text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    color: white;
    padding: 10px;
    width: 100%;
    text-align: start;
    box-sizing: border-box;
}

@media screen and (max-width:768px) {
    .gallery {
        grid-auto-rows: 200px;

    }

    .mobile-col-2 {
        grid-column: span 2;
    }
}