.locations .card-item {
  border: 1px solid rgba(82, 86, 94, 0.2);
  background: #fff;
  position: relative;
  border-radius: 0;
}

.locationcardrow{
  display: flex;
}

.locations .card-item .card-bg {
  min-height: 250px;
  background-image: url(../../Assets/3d\ architectural\ rendering\ services\ apartment\ visualization.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.locations .card-item .card-body {
  padding: 30px;
}

.locations .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--color-secondary);
}

.locations .card-item p {
  color: var(--color-secondary);
  margin: 0;
}

@media screen and (max-width:768px) {

  .locations .card-item .card-bg {
    min-height: 170px;
  }

  .locations .card-item .card-body {
    padding: 15px;
    min-height: 170px;
  }
}