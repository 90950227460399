.breadcrumbs {
    padding: 190px 0 60px 0;
    min-height: 55vh;
    position: relative;
    background-image: url(../../Assets/breadcrumbs-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .breadcrumbs:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    inset: 0;
  }
  
  .breadcrumbs h2 {
    font-size: 56px;
    font-weight: 500;
    color: #fff;
    font-family: var(--font-secondary);
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary);
  }
  
  .breadcrumbs ol a {
    color: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
  }
  
  .breadcrumbs ol a:hover {
    text-decoration: underline;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #fff;
    content: "/";
  }

  @media screen and (max-width:768px) {
    .breadcrumbs {
      padding: 100px 0 40px 0;
      min-height: 35vh;
    }

    .breadcrumbs h2 {
      font-size: 40px;
    }
    
  }