.header-carousel {
    height: 100%;
}

.carousel-slider .slick-next {
    position: absolute;
    top: 80%;
    right: 20%;
    transform: translateY(-50%);
    margin: 7px 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    /* border: 1px solid #FFFFFF; */
    font-size: 40px;
    transition: .5s;
    cursor: pointer;
}

.carousel-slider .slick-prev {
    position: absolute;
    top: 80%;
    right: 50%;
    transform: translateY(-50%);
    margin: 7px 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: transparent;
    border: 1px solid #FFFFFF;
    font-size: 22px;
    transition: .5s;
    cursor: pointer;
}

.carousel-slider .slick-arrow:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.herosectionheading {
    font-weight: 700;
    font-size: 3.5rem;
}

.herosectioninnerdiv {
    padding: 100px  0  0 100px;
}

.carouselimg {
    height: 95vh;
}

.buttonclass button{
    background: #feb900;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

@media screen and (max-width:1024px) {
    .herosectioninnerdiv {
        padding: 60px  0  0 60px;
    }
    
}

@media screen and (max-width:768px) {
    .herosectiondiv {
        height: 65vh;
    }

    .carouselimg {
        height: 65vh;
    }


    .herosectioninnerdiv {
        padding: 100px 20px 0 20px;
    }
}

@media (max-width: 600px) {
    .herosectiondiv {
        height: 85vh;
    }

    .carouselimg {
        height: 85vh;
    }


    .herosectioninnerdiv {
        padding: 40px 20px 0 20px;
    }

    .playstorefooterbtn1 {
        width: 100%;
    }

    .headerimageul {
        width: 80%;
    }

    .herosectionheading {
        font-size: 2rem;
    }

    .carousel-slider .slick-next {
        top: 84%;
        right: 8%;
    }

}
