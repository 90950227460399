.about{
    padding: 80px 0;
}
.about-img {
    background-image: url(../../Assets/3d\ architectural\ rendering\ services\ apartment\ visualization.jpg);
}

.about h2 {
    font-size: 3rem;
    font-weight: 700;
    font-family: var(--font-secondary);
    margin: 30px 0;
}

@media (min-width: 991px) {
    .about h2 {
        max-width: 65%;
        margin: 0 0 80px 0;
    }
}

.about .our-story {
    padding: 40px;
    background-color: #f5f6f7;
}

@media (min-width: 991px) {
    .about .our-story {
        padding-right: 35%;
    }
}

.about .our-story h4 {
    text-transform: uppercase;
    font-size: 18px;
    color: #838893;
}

.about .our-story h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--color-secondary);
}

.about .our-story p:last-child {
    margin-bottom: 0;
}

.about ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.about ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
}

.about ul i {
    font-size: 20px;
    margin-right: 4px;
    color: var(--color-primary);
}

.about .watch-video i {
    font-size: 32px;
    transition: 0.3s;
    color: var(--color-primary);
}

.about .watch-video a {
    font-weight: 600;
    color: var(--color-secondary);
    margin-left: 8px;
    transition: 0.3s;
}

.about .watch-video:hover a {
    color: var(--color-primary);
}

.about .about-img {
    min-height: 600px;
    background-size: cover;
    background-position: center;
}

@media (min-width: 992px) {
   
    .about .about-img {
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media screen and (max-width:768px) {
    .about{
        padding: 30px 0;
    }

    .about .about-img {
        min-height: 280px;
    }

    .about h2 {
        font-size: 2.2rem;
        margin-bottom: 10px;
    }
    
}