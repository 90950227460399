.inquiryection{
    padding: 30px;
    width: 50vh;
    height: auto;
    border-radius: 8px;
    background-color: white;
    backdrop-filter: blur(0.8);
    opacity: 1;
    z-index: 999;
    overflow: auto;
    /* border: 2px solid white; */
}

.inquiryheading{
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
    color: black;
}

.inquiryinput{
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid #feb900;
    margin-bottom: 20px;
    color: black;
}

.inquirylabel{
    color: black;
    font-weight: 600;
}

.inquiryinputbtn{
    width: 100%;
    padding: 15px;
    font-weight: 600;
    background-color: #feb900 !important; 
    color: white;
    border: none;
}

/* @media screen and (max-width:600px) {
    .inquiryection{
        height: 90vh;
    }
} */