.service-item {
    /* padding: 40px; */
    background: #fff;
    height: 100%;
}

.services .service-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 50px;
}

.services .service-item .icon i {
    color: black;
    font-size: 40px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
    line-height: 1.8;
}

.services .service-item .icon::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #f0f1f2;
    border-radius: 50px;
    z-index: 1;
    top: 10px;
    right: -15px;
    transition: 0.3s;
}

.services .service-item h3 {
    color: var(--color-default);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid #ebebed;
    transition: 0.3s;
}

.services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .service-item .readmore {
    margin-top: 15px;
    display: inline-block;
    color: #feb900;
}

.services .service-item:hover .icon:before {
    background: #feb900;
}

.services .service-item:hover h3 {
    border-color: #feb900;
}

@media screen and (max-width:1024px) {
    .services .service-item h3 {
        font-size: 20px;
    }
}
@media screen and (max-width:768px) {
    .service-item {
        padding: 25px 0;
        width: 100%;
    }

    .services .service-item .icon::before {
        right: -10px;
    }

    .services .service-item h3 {
        font-size: 18px;
    }
}