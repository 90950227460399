
.logoutbtndiv{
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.logoutbtninnerdiv{
    padding: 30px;
}

.uploadimagerow{
    width: 95%;
    display: flex;
    margin: 30px 0;
}

.uploadimagecolumn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    row-gap: 10px;
}

.uploadimagecolumninnerdiv{
    border-Radius: 5px;
    padding: 20px;
    width: 80%;
    box-Sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.uploaddatadiv{
    display: flex;
    gap: 20px;
}

.uploadimageinputdiv{
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 50%;
}

.uploadimagecolumninnerdiv > input,textarea,select{
    padding: 10px;
}

.uploadimagecolumninnerdiv > label{
    margin: 10px 0;
}

.uploadimageimage{
    width:100%;
    height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
}

.uploadimagebtn{
  padding: 10px 15px;
  background-color: #ffd301;
  border: none;
  color: white;
  border-radius: 15px;
  font-weight: 700;
  margin-top: 20px !important;
  width: 50%;
  cursor: pointer;
}

.logoutbtn{
    padding: 10px 15px;
    background-color: #ffd301;
    border: none;
    color: white;
    border-radius: 15px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;  
}

.loadingimagediv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.loadingimageinnerdiv{
    width: 50%;
}

.loadingimageimagediv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingimage{
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.loadingimagemessage{
    margin-top: 30px;
    text-align: center;
}

.mappedimagesdiv{
    display: flex;
    width: 100%;
    gap: 20px;
    margin: 10px 0;
}

.mappedimagesdiv > input{
    width: 70%;
    padding: 10px;
}

.mappedimages{
    width: 30%;
    height: 50px;
}

@media screen and (max-width:600px){
    .uploadimagerow{
        flex-direction: column-reverse;
        gap: 20px;
        width: 100%;
    }
    .uploadimagecolumn{
        width: 100%;
    }

    .uploadimageimage{
        width:90%;
        height: 250px;
        border-radius: 10px;
    }
    
}