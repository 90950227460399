.client .client-item {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.client .client-img {
    position: relative;
}

.client .client-img img {
    width: 100%;
}

.client .client-text {
    position: relative;
    padding: 25px 15px;
    text-align: center;
    background: #303441;
    transition: .5s;
}

.client .client-text h2 {
    font-size: 20px;
    font-weight: 600;
    color: #fdbe33;
    transition: .5s;
}

.client .client-text p {
    margin: 0;
    color: #ffffff;
}

.client .client-item:hover .client-text {
    background: #fdbe33;
}

.client .client-item:hover .client-text h2 {
    color: #303441;
    letter-spacing: 1px;
}

.client .client-social {
    position: absolute;
    width: 100px;
    top: 0;
    left: -50px;
    display: flex;
    flex-direction: column;
    font-size: 0;
}

.client .client-social a {
    position: relative;
    left: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
}

.client .client-item:hover .client-social a:first-child {
    background: #00acee;
    left: 50px;
    transition: .3s 0s;
}

.client .client-item:hover .client-social a:nth-child(2) {
    background: #3b5998;
    left: 50px;
    transition: .3s .1s;
}

.client .client-item:hover .client-social a:nth-child(3) {
    background: #0e76a8;
    left: 50px;
    transition: .3s .2s;
}

.client .client-item:hover .client-social a:nth-child(4) {
    background: #3f729b;
    left: 50px;
    transition: .3s .3s;
}


@media screen and (max-width:768px) {
    .client .client-img img {
        height: 150px;
    }

    .client .client-text {
        padding: 15px;
    }
}