.listing-card {
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    margin: 0 15px 30px 0;
}

.listing-card img {
    transition: .5s;
    height: 250px;
    width: 100%;
}

.new {
    background-color: #feb900;
}

.listing-card:hover img {
    transform: scale(1.1);
}

.listing-card small a:hover {
    color: var(--primary) !important;
}

.text-body{
    text-align: end;
}

.listcardbtndiv{
    display: flex;
    justify-content: flex-end;
}

.listcardbtn{
    background: #feb900;
    border: 0;
    padding: 5px 10px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
    margin-top: 5px;
}

@media screen and (max-width:600px) {
    .listing-card {
        margin: 0 0 30px 0;
        display: flex;
    }

    .listing-card img {
        height: 150px;
    }

}