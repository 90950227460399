
.projects .portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .projects .portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    margin: 0 10px;
    line-height: 1;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .projects .portfolio-flters li:hover,
  .projects .portfolio-flters li.filter-active {
    color: #feb900;
  }
  
  .projects .portfolio-flters li:first-child {
    margin-left: 0;
  }
  
  .projects .portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .projects .portfolio-flters li {
      font-size: 14px;
      margin: 0 5px;
    }
  }
  
