section {
  padding: 80px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f6f7;
}

.section-header {
  text-align: center;
  padding-bottom: 70px;
}

.section-header h2 {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  color: #2e3135;
  margin: 0 auto 0 auto;
}

.section-header h2:before,
.section-header h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: #fdbe33;
  display: inline-block;
}

.section-header h2:before {
  margin: 0 15px 10px 0;
}

.section-header h2:after {
  margin: 0 0 10px 15px;
}

.section-header p {
  margin: 0 auto 0 auto;
}

@media (min-width: 1199px) {
  .section-header p {
    max-width: 60%;
  }
}

a {
  text-decoration: none !important;
}

@media screen and (max-width:768px) {
  section {
    padding: 30px 0;
  }

  .section-header h2:before,
  .section-header h2:after {
    display: none;
  }
}

.model{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  z-index: 999;
}


.model.open{
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition:opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

}

.model.open::after{
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  /* height: 135vh; */
  bottom: 0;
  background-color: black;
  opacity: 0.7;
  z-index: -1;
}

.modelcloseButton{
  position: fixed;
  top: 110px;
  right: 10px;
  padding: 5px;
  font-size: 35px;
  background: transparent;
  color: white;
  cursor: pointer;
  z-index: 999;
  border: none;
}

@media screen and (max-width:600px) {
  .modelcloseButton{
      position: absolute;
      right: 10px;
      top: 10px;
      color: white;
  }

  .model.open{
      height: 100vh;
  }
}