.counter {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.counter_form {
    width: 450px;
    max-height: 55%;
    background: #FFFFFF;
    padding: 40px;
    box-shadow: 0px 5px 40px rgba(29, 34, 47, 0.15);
}

.counter_form_content {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.counter_form_title {
    font-family: 'Roboto Slab', serif;
    font-size: 24px;
    font-weight: 700;
    color: #384158;
    text-transform: uppercase;
    line-height: 0.75;
    margin-bottom: 41px;
}

.counter_input {
    width: 100%;
    height: 46px;
    border: solid 1px #e5e5e5;
    border-radius: 3px;
    padding-left: 20px;
    outline: none;
    color: #384158;
    font-size: 14px;
}

.counter_input:not(:last-child) {
    margin-bottom: 20px;
}

.counter_text_input {
    height: 90px;
    padding-top: 10px;
}

.counter_options {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    background-image: url(../../Assets/down.png);
    background-position: center right;
    background-repeat: no-repeat;
}

.counter_input::-webkit-input-placeholder,
.counter_text_input::-webkit-input-placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #b5b8be !important;
}

.counter_input:-moz-placeholder,
.counter_text_input:-moz-placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #b5b8be !important;
}

.counter_input::-moz-placeholder,
.counter_text_input::-moz-placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #b5b8be !important;
}

.counter_input:-ms-input-placeholder,
.counter_text_input:-ms-input-placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #b5b8be !important;
}

.counter_input::input-placeholder,
.counter_text_input::input-placeholder {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #b5b8be !important;
}

.counter_form_button {
    width: 100%;
    height: 46px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    outline: none;
    background: #ffd301;
    cursor: pointer;
    margin-top: 30px;
    box-shadow: 0px 5px 40px rgba(29, 34, 47, 0.15);
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}



.counter_form_button:hover {
    box-shadow: 0px 5px 40px rgba(29, 34, 47, 0.45);
}

.counter_form_content p{
    margin-top: 20px;
}