
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 15px 15px 15px 30px;
    margin: 0 15px 30px 0;
    min-height: 150px;
    box-shadow: 0px 2px 20px rgba(82, 86, 94, 0.1);
    position: relative;
    background: #fff;
  }
  
  .testimonialinnerdiv{
    display: flex;
    gap: 20px;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    /* position: absolute; */
    /* left: -45px; */
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #000;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .stars {
    margin: 10px 0;
  }
  
  .testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: #ffd565;
    font-size: 26px;
    line-height: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
  }
  
  @media (max-width: 767px) {
    .testimonials .testimonial-wrap {
      padding-left: 0;
    }
  
    .testimonials .testimonials-carousel,
    .testimonials .testimonials-slider {
      overflow: hidden;
    }
  
    .testimonials .testimonial-item {
      padding: 30px;
      margin: 15px;
    }
  
    .testimonials .testimonial-item .testimonial-img {
      position: static;
      left: auto;
    }
  }